import React from "react";

import { SharedLayout } from "../layouts/SharedLayout";
import { CarTable } from "components/CarTable";
import SEO from "../components/seo";

const IndexPage = () => (
  <SharedLayout>
    <SEO title="SUVs" />
    <h1>Suvs</h1>
    <CarTable carType="SUV" carSize="midsize" />
  </SharedLayout>
);

export default IndexPage;
